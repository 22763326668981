import { useState, useContext, useEffect } from 'react';
import './index.css';
import {useNavigate} from 'react-router-dom';
import AITable from '../AITable/AITable';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Header from "../Header/Header";
import UserContext from "../../UserContext";
import { CloudArrowUp, Trash } from "@phosphor-icons/react";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {DNA} from 'react-loader-spinner';
import Footer from '../Footer/Footer';
import jsSHA from "jssha";
import NDashboard from '../NDashboard';
import SearchIcon from '@mui/icons-material/Search';




function AIAnswerReviewB2B() {
  const [loading, setLoading] = useState(false); 
  const [pdfFiles, setPdfFiles] = useState([]);
  const [loggedInUserDetails] = useContext(UserContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedOption, setSelectedOption] = useState('GS');
  const [showOptional, setShowOptional] = useState(false);
  const [assignmentName, setAssignmentName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    if (!loggedInUserDetails?.user_id) {
      navigate("/login");
    }
  }, [loggedInUserDetails,navigate]);
  

  const handleAssignmentNameChange = (e) => {
    setAssignmentName(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const payload = {
        user_id: loggedInUserDetails.user_id,
        client:'narayanagroup',
        search : searchQuery
      };
  
      const response = await axios.post(
        'https://collectorbabu.com/api/template/search_template',
        payload
      );
  
      if (Array.isArray(response.data)) {
        const filteredResults = response.data.filter(result => {
          const searchTerm = searchQuery.toLowerCase();
            return result.assignment_name.toLowerCase().includes(searchTerm);
        });
      
        setSearchResults(filteredResults);
      }
      
  
    } catch (error) {
      console.error('Error searching template:', error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch(); 
    }
  }, [searchQuery]);

  const handleSearchQueryChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  
    if (query === '') {
      setSearchResults([]);
      return;
    }
    
  };



  const handleOptionalClick = () => {
    setShowOptional(true);
    setSelectedOption('');
  };

  const handleBackClick = () => {
    setShowOptional(false);
    setSelectedOption('');
  };

  const handlePdfDrop = (event) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    handlePdfChange({ target: { files: fileList } });
  };

  const calculateFileHash = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const buffer = reader.result;
        const shaObj = new jsSHA("SHA-256", "ARRAYBUFFER");
        shaObj.update(buffer);
        const hashHex = shaObj.getHash("HEX");
        resolve(hashHex);
      };
      reader.onerror = () => {
        reject("File reading failed");
      };
      reader.readAsArrayBuffer(file);
    });
  };
  
  const handlePdfChange = async (event) => {
    const fileList = event.target.files;
    const newPdfs = [];
  
    for (let i = 0; i < Math.min(fileList.length, 1); i++) {
      const file = fileList[i];
  
      if (file.type !== "application/pdf") {
        alert("Please upload a valid PDF file.");
        return;
      }
  
      try {
        const hash = await calculateFileHash(file);
        
        const isDuplicate = pdfFiles.some((pdf) => pdf.hash === hash);
        if (isDuplicate) {
          alert(`The file "${file.name}" is already uploaded.`);
          continue;
        }
  
        newPdfs.push({ file, hash });
      } catch (error) {
        alert(`Error processing file "${file.name}": ${error}`);
        return;
      }
    }
  
    setPdfFiles((prevFiles) => [...prevFiles, ...newPdfs]);
  };
   
  const deletePdf = (index) => {
    const newPdfFiles = [...pdfFiles];
    newPdfFiles.splice(index, 1);
    setPdfFiles(newPdfFiles);
  };

  const handlePdfFileChange = (e) => {
    const files = Array.from(e.target.files);
    setPdfFiles([...pdfFiles, ...files]);
  };

  const handlePdfDragOver = (e) => {
    e.preventDefault();
  };


  const handleMultipleSubmit = async () => {
    if (!loggedInUserDetails.user_id) {
        alert("Please login first");
        return;
    }

    if (pdfFiles.length === 0) {
        alert("Please upload pdf");
        return;
    }

    setLoading(true);
    try {
        for (let i = 0; i < pdfFiles.length; i++) {
            const fileName = pdfFiles[i].name;  

            try {
                const formData = new FormData();
                formData.append("mains_answer_sheet_pdf", pdfFiles[i]);

                const response = await axios.post(
                    "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
                    formData,
                    {
                        params: {
                            user_id: loggedInUserDetails.user_id,
                            subject: selectedOption,
                            assignment_name: assignmentName,
                        },
                        headers: {
                            accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.status === 200) {
                    console.log(`${fileName} uploaded successfully`);
                    setSnackbarMessage(`${fileName} uploaded successfully.`);
                    setSnackbarSeverity("success");
                } else {
                    console.error(`Error uploading ${fileName}`);
                    setSnackbarMessage(`Error uploading ${fileName}.`);
                    setSnackbarSeverity("error");
                }
            } catch (error) {
                console.error(`Error uploading PDF ${fileName}:`, error);
                setSnackbarMessage(`Error uploading PDF ${fileName}.`);
                setSnackbarSeverity("error");
            } finally {
                setSnackbarOpen(true);
            }
        }

        setTimeout(() => {
            setPdfFiles([]);
        }, 5000);
    } catch (error) {
        console.error("Error submitting PDFs:", error);
        setSnackbarMessage("There was an error uploading your PDFs.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    } finally {
        setLoading(false);
    }
};

  
  


  //this function is used to close the alert message
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAssignmentNameClick = (selectedResult) => {
    setSearchResults([]);
    setSearchQuery('');
    setAssignmentName(selectedResult.assignment_name);
  };


  return (
<>
<Header/>
          {/* <div className="home-container flex flex-col"> */}
          <div>
              <h1 className="main-heading-text">Bulk PDF Upload</h1>
              </div>
            <div className="adjustment">
            {/* <h1 style={{textAlign: 'center',marginBottom: '20px'}} className="heading-text"></h1> */}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='pdf-upload-container-b2c-n'>
                <div style={{ marginBottom: '15px' }} className="options-list-container-b2c">
      {!showOptional ? (
        <>
        <Stack style={{marginTop: '35px'}} direction="row" spacing={2}>
          <Button style={{ backgroundColor: selectedOption === 'GS' ? 'blue' : 'white', color: selectedOption === 'GS' ? 'white' : 'black' }} variant="contained" onClick={() => setSelectedOption('GS')}>GS 1/2/3</Button>
          <Button style={{ backgroundColor: selectedOption === 'GS4' ? 'blue' : 'white', color: selectedOption === 'GS4' ? 'white' : 'black' }}
        variant="contained" onClick={() => setSelectedOption('GS4')}>GS4</Button>
          <Button style={{ backgroundColor: selectedOption === 'ESSAY' ? 'blue' : 'white', color: selectedOption === 'ESSAY' ? 'white' : 'black' }}
       variant="contained" onClick={() => setSelectedOption('ESSAY')}>Essay</Button>
        </Stack>
        
        <Stack direction="row" spacing={2} style={{ marginTop: '10px' }}>
         <Button style={{ backgroundColor: 'white', color:  'black'  }} variant="contained" onClick={handleOptionalClick}>Optional</Button>
        </Stack>
        </>
      ) : (
        <div style={{marginTop:'90px'}} className='optional-container-b2c-mb'>
          <Button style={{width:'100px', backgroundColor: 'white', color:  'black'  }} variant="contained" startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
            Back
          </Button>
          <Stack direction="row" spacing={2} style={{ marginTop: '10px' }}>
            <Button  style={{ backgroundColor: selectedOption === 'SOCIOLOGY' ? 'blue' : 'white', color: selectedOption === 'SOCIOLOGY' ? 'white' : 'black' }}
           variant="contained" onClick={() => setSelectedOption('SOCIOLOGY')}>Sociology</Button>
            <Button   style={{ backgroundColor: selectedOption === 'PSIR' ? 'blue' : 'white', color: selectedOption === 'PSIR' ? 'white' : 'black' }}
          variant="contained" onClick={() => setSelectedOption('PSIR')}>PSIR</Button>
            <Button   style={{ backgroundColor: selectedOption === 'ANTHROPOLOGY' ? 'blue' : 'white', color: selectedOption === 'ANTHROPOLOGY' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('ANTHROPOLOGY')}>Anthropology</Button>
        </Stack>
           
          <Stack direction="row" spacing={2} style={{ marginTop: '10px' }}>
          <Button   style={{ backgroundColor: selectedOption === 'TELANGANAMOVEMENT' ? 'blue' : 'white', color: selectedOption === 'TELANGANAMOVEMENT' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('TELANGANAMOVEMENT')}>TELANGANAPCS</Button>
          <Button  style={{ backgroundColor: selectedOption === 'LAW' ? 'blue' : 'white', color: selectedOption === 'LAW' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('LAW')}>Law</Button>
         <Button  style={{ backgroundColor: selectedOption === 'HISTORY' ? 'blue' : 'white', color: selectedOption === 'HISTORY' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('HISTORY')}>HISTORY</Button>
          <Button  style={{ backgroundColor: selectedOption === 'PUBAD' ? 'blue' : 'white', color: selectedOption === 'PUBAD' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('PUBAD')}>PUBAD</Button>
          </Stack>

          <Stack direction="row" spacing={2} style={{ marginTop: '10px' }}>
          <Button   style={{ backgroundColor: selectedOption === 'GEOGRAPHY' ? 'blue' : 'white', color: selectedOption === 'GEOGRAPHY' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('GEOGRAPHY')}>GEOGRAPHY</Button>
          <Button  style={{ backgroundColor: selectedOption === 'PHILOSOPHY' ? 'blue' : 'white', color: selectedOption === 'PHILOSOPHY' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('PHILOSOPHY')}>PHILOSOPHY</Button>
         <Button  style={{ backgroundColor: selectedOption === 'PSYCHOLOGY' ? 'blue' : 'white', color: selectedOption === 'PSYCHOLOGY' ? 'white' : 'black' }}
         variant="contained" onClick={() => setSelectedOption('PSYCHOLOGY')}>PSYCHOLOGY</Button>
          
          </Stack>
          
        </div>
      )}
    </div>
                <div className='form-container-b2b'>
                            {assignmentName && (
              <p className="test-code-name">
                Selected Test Code: {assignmentName}
                <span className="remove-icon" onClick={() => setAssignmentName(null)}>×</span>
              </p>
            )}
<div className="search-container" style={{ position: 'relative' }}>
  <input
    type="text"
    value={searchQuery}
    onChange={handleSearchQueryChange}
    placeholder={'Search Test Code...'}
    className="search-input"
  />
  <button onClick={handleSearch} className="search-btn">
    <SearchIcon size={30} />
  </button>

  {searchResults.length > 0 && (
    <ul className="search-results-dropdown">
      {searchResults.map((result, index) => (
        <li key={index} className="search-result-item" onClick={() => handleAssignmentNameClick(result)}>
          {result.assignment_name}
        </li>
      ))}
    </ul>
  )}
</div>

                
                <div className="pdf-upload-container">
      
      <div className="upload-indicator">
        <CloudArrowUp size={32} color="#5c5c5c" />
      </div>

      <div className="file-container" id="pdfContainer" onDragOver={handlePdfDragOver} onDrop={handlePdfDrop}>
        {pdfFiles.length === 0 && (
          <div className='upload-info' onClick={() => document.getElementById('pdfInput').click()}>
            <div className="flex justify-center">
              <div className="upload-text">
                <span>Click to upload</span>
                <span>or drag and drop</span>
              </div>
            </div>
          </div>
        )}
        {pdfFiles.map((pdf, index) => (
          <div key={index} className="pdf-list-container_">
            <div className="pdf-wrapper_">
              <p className="pdf-name_">{pdf.name}</p>
              <button onClick={() => deletePdf(index)} className="pdf-delete-button_">
                <Trash size={25} color="#fb3232" />
              </button>
            </div>
          </div>
        ))}

        <input type="file" id="pdfInput" accept=".pdf" style={{ display: 'none' }} multiple onChange={handlePdfFileChange} />
        
        {pdfFiles.length > 0 && (
        <button onClick={() => document.getElementById('pdfInput').click()} className="upload-more-button">
            Upload More PDFs
        </button>
    )
}

      </div>
      
    </div>
    </div>
    {loading && <DNA
  visible={true}
  height="80"
  width="80"
  ariaLabel="dna-loading"
  wrapperStyle={{}}
  wrapperClass="dna-wrapper"
  />}
    <button style={{marginTop:'20px', marginBottom:'20px'}} onClick={handleMultipleSubmit} className="submit-button-b2c">
        Submit PDF
      </button>
      <br/>
      <br/>
      <br/>
      <div style={{paddingBottom:'140px'}}></div>
                </div>
            </div>
            {/* </div> */}

              {/* <div style={{marginTop:'90px'}} className='pdf-container'> */}

                <NDashboard/>

            {/* </div> */}
          <Footer/>
        <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
</>
  );
}
export default AIAnswerReviewB2B;

